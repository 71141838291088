
import { defineComponent, onMounted } from "vue";
import SalesOrderOrderOverviewImport from "./CommonImport.vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
export default defineComponent({
  name: "sales-order-order-overview-import",
  components: { SalesOrderOrderOverviewImport },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      setModuleBCN(t, route, router);
    });
  },
});
